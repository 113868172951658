import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contractDetails: null,
  newClaim: null,
  claimDetails: null,
};

const slice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getContractDetailsSuccess(state, action) {
      state.isLoading = false;
      state.contractDetails = action.payload;
    },
    getClaimDetailsSuccess(state, action) {
      state.isLoading = false;
      state.claimDetails = action.payload;
    },
    createNewClaimSuccess(state, action) {
      state.isLoading = false;
      state.newClaim = action.payload;
    },

    clearData(state, action) {
      state.isLoading = false;
      state.error = null;
      state.contractDetails = null;
      state.newClaim = null;
      state.claimDetails = null;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export const { clearData } = slice.actions;
const baseUrl = 'https://bol.mondial-assistance.gr/mddiapi';

export function getContractDetails(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${baseUrl}/v2/GetContractDetails`, payload);
      dispatch(slice.actions.getContractDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getClaimDetails(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${baseUrl}/v2/GetClaim`, payload);
      dispatch(slice.actions.getClaimDetailsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function createNewClaim(payload) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`${baseUrl}/v2/CreateNewClaim`, payload);
      console.log({ response });
      dispatch(slice.actions.createNewClaimSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
