import {Navigate, useRoutes} from 'react-router-dom';
// auth
// layouts
import CompactLayout from '../layouts/compact';
// config
//
import {AppliancePage, LoginPage, Page404} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',

          element: (

              <LoginPage />

          ),

    },
    {
      path: '/appliance',
      element: (
          <AppliancePage />
      ),
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
