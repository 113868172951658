import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { Box } from '@mui/material';
import Image from '../image';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        mt: 2,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <Image disabledEffect visibleByDefault alt="auth" src={'/assets/illustrations/logo.png'} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return logo
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
